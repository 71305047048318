.Avatar {
  --color-user: var(--color-primary);
  --radius: 50%;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 3.375rem;
  height: 3.375rem;
  border-radius: var(--radius);
  background: linear-gradient(var(--color-white) -125%, var(--color-user));
  color: white;
  font-weight: bold;
  display: flex;
  white-space: nowrap;
  user-select: none;
  position: relative;

  &__media {
    border-radius: var(--radius);
    width: 100%;
    height: 100%;
  }

  .emoji {
    width: 1rem;
    height: 1rem;
  }

  &__icon {
    font-size: 1.25rem;
  }

  &.size-micro {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;

    .emoji {
      width: 0.5625rem;
      height: 0.5625rem;
    }
  }

  &.size-tiny {
    width: 2rem;
    height: 2rem;
    font-size: 0.875rem;

    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-mini {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.75rem;

    .emoji {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  &.size-small {
    width: 2.125rem;
    height: 2.125rem;
    font-size: 0.875rem;

    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-small-mobile {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.875rem;

    .emoji {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  &.size-medium {
    width: 2.75rem;
    height: 2.75rem;
    font-size: 1.1875rem;

    .emoji {
      width: 1rem;
      height: 1rem;
    }
  }

  &.size-large {
    font-size: 1.3125rem;

    i {
      font-size: 1.625rem;
    }

    .emoji {
      width: 1.3125rem;
      height: 1.3125rem;
    }
  }

  &.size-jumbo {
    width: 7.5rem;
    height: 7.5rem;
    font-size: 3.5rem;

    &__i {
      font-size: 6rem;
    }

    .emoji {
      width: 3.5rem;
      height: 3.5rem;
    }
  }

  &.online {
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0.0625rem;
      right: 0.0625rem;
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      border: 2px solid var(--color-background);
      background-color: #0ac630;
      flex-shrink: 0;
    }
  }

  &.interactive {
    cursor: pointer;
  }

  .poster {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &.forum {
    --radius: var(--border-radius-forum-avatar);
  }
}
