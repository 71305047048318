.Modal {
  position: relative;
  z-index: var(--z-modal);

  &.confirm {
    z-index: var(--z-lock-screen);
  }

  &.delete,
  &.error,
  &.confirm,
  &.pin,
  &.unpin-all {
    .modal-dialog {
      max-width: 23rem;
    }
  }

  &.error {
    .modal-content > div {
      margin-top: 1rem;
    }
  }

  &.narrow {
    .modal-dialog {
      max-width: 20rem;
    }
  }

  &.slim {
    .modal-dialog {
      max-width: 25rem;
    }

    .modal-content {
      max-height: min(92vh, 32rem);
    }
  }

  .modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.25);
  }

  &.transparent-backdrop .modal-backdrop {
    background-color: transparent;
  }

  .modal-dialog {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    max-width: 35rem;
    min-width: 17.5rem;
    margin: 2rem auto;
    background-color: var(--color-background);
    box-shadow: 0 0.25rem 0.5rem 0.125rem var(--color-default-shadow);
    border-radius: var(--border-radius-default);
    transform: translate3d(0, -1rem, 0);

    transition: transform 0.2s ease, opacity 0.2s ease;

    body.animation-level-0 & {
      transition: none;
      transform: none !important;
    }

    @media (max-width: 450px) {
      max-width: calc(100vw - 3rem) !important;
    }
  }

  &.open .modal-dialog {
    transform: translate3d(0, 0, 0);
  }

  &.closing .modal-dialog {
    transform: translate3d(0, 1rem, 0);
  }

  .modal-header,
  %modal-header {
    padding: 1rem 1rem 0;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }

  .modal-title {
    font-size: 1.25rem;
    font-weight: 500;
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:only-child) {
      margin: 0 1.3125rem;
    }
  }

  .modal-header-condensed {
    @extend %modal-header;
    padding: 0.5rem 1.25rem 0 0.9375rem !important;

    .modal-action-button {
      font-size: 0.875rem;
      height: 2.25rem;
      width: auto;
      line-height: 1.625rem;
      margin-left: auto;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      min-width: 5rem;

      &.danger {
        background-color: var(--color-error);
        color: var(--color-white);

        &:hover,
        &:focus,
        &:active {
          background-color: var(--color-error-shade);
        }
      }
    }
  }

  .modal-content {
    width: 100%;
    flex-grow: 1;
    padding: 1rem;
    overflow-y: auto;
    max-height: 92vh;

    b,
    strong {
      word-break: break-word;
    }
  }

  .modal-title,
  .modal-content,
  .modal-content > p {
    unicode-bidi: plaintext;
    text-align: initial;
  }

  .modal-about {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .modal-help {
    color: var(--color-text-secondary);
    font-size: 0.9375rem;
    line-height: 1.3;
  }

  .dialog-buttons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    flex-wrap: wrap;

    .confirm-dialog-button + .confirm-dialog-button {
      margin-right: 1rem;
    }
  }

  .dialog-buttons-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .dialog-checkbox {
    margin: 1rem 0;
  }

  .confirm-dialog-button {
    width: auto;
    height: auto;
    text-align: right;
    font-weight: 500;
    white-space: pre-wrap;
  }

  .dialog-button-spacer {
    flex-grow: 1;
  }
}
